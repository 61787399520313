import React from "react";
import { FormFeedback, FormGroup, FormGroupProps, Label } from "reactstrap";
import { getFieldError } from "../../../functions/forms";
import { SelectOptionsT } from "../../../types/Common";
import {
  BaseInputController,
  BaseInputControllerT,
} from "./BaseInputController";

import { FieldPath, FieldValues } from "react-hook-form";
import classNames from "classnames";
import styles from "./inputController.module.scss";
import { SearchableSelect } from "../../atoms/SearchableSelect/SearchableSelect";

type PropsT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseInputControllerT<TFieldValues, TName> & {
  label?: string;
  radioOptions?: SelectOptionsT;
  className?: string;
  isRadioSwitch?: boolean;
  formGroup?: FormGroupProps;
  required?: boolean;
  searchable?: boolean;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  id,
  label,
  errors,
  radioOptions,
  input,
  isRadioSwitch,
  formGroup,
  required,
  searchable,
  ...rest
}: PropsT<TFieldValues, TName>) => {
  const fieldError = getFieldError(name, errors);
  const isCheckbox = input?.type === "checkbox";

  let controller = (
    <BaseInputController
      {...rest}
      input={input}
      errors={errors}
      name={name}
      id={id}
    />
  );
  if (radioOptions) {
    controller = (
      <div className={classNames(isRadioSwitch && styles["switch-group"])}>
        {radioOptions.map((radio) => (
          <FormGroup
            key={radio.value}
            check
            className={classNames(isRadioSwitch && styles["switch-wrapper"])}
          >
            <BaseInputController
              {...rest}
              errors={errors}
              name={name}
              input={{ ...input, value: radio.value, type: "radio" }}
              id={`${name}-${radio.value}`}
            />
            <Label
              check
              for={`${name}-${radio.value}`}
              className={classNames(
                isRadioSwitch && [styles.switch, "btn btn-outline-dark btn-sm"]
              )}
            >
              {radio.label}
            </Label>
          </FormGroup>
        ))}
      </div>
    );
  }

  if (searchable && rest.options) {
    controller = (
      <SearchableSelect
        {...rest}
        input={input}
        errors={errors}
        name={name}
        id={id}
      />
    );
  }

  return (
    <FormGroup
      {...formGroup}
      check={isCheckbox}
      className={classNames(formGroup?.className, styles["wrapper-hack"], {
        [styles["form-group--date"]]: input?.type === "date",
      })}
    >
      {label && !isCheckbox && (
        <Label className="d-block" for={id}>
          {label}
          {required ? <span className={styles.required}> *</span> : ""}
        </Label>
      )}

      {controller}

      {label && isCheckbox && <Label for={id}>{label}</Label>}

      {fieldError && <FormFeedback>{fieldError.message}</FormFeedback>}
    </FormGroup>
  );
};
