import {
  CoordinateT,
  JourneyTypes,
  LatLngT,
  RouteLineSetting,
  RouteSettingType,
  RouteTypes,
  ShowActivityTypesT,
} from "../types/Route";
import { RouteLineInfo } from "../components/molecules/RouteLineInfoWindow/RouteLineInfoWindow";

export const getLayerName = (layerType: JourneyTypes | RouteTypes): string => {
  switch (layerType) {
    case "endPoint":
      return "End point";
    case "startPoint":
      return "Start point";
    case "mowingRoute":
      return "Route";
    case "mowingRoute_5cm":
      return "Mowing route 5cm";
    case "noGPSSignalZone":
      return "No GPS signal zone";
    case "noGpsMowing":
      return "No GPS route";
    case "noGoZone":
      return "No-go zone";
    case "geofence":
      return "Perimeter";
    case "arrow":
      return "Arrow";
    case "autonomous":
      return "Autonomous";
    case "fix_location":
      return "Fix location";
    case "grass_cutting":
      return "Grass cutting";
    case "manual":
      return "Manual";
    case "marker":
      return "Marker";
    case "rtk_location":
      return "RTK location";
    case "without_gps_location":
      return "Without GPS location";
    case "transitRoute":
      return "Transit route";
    default:
      return "Layer";
  }
};

const activityTypesLabels: Record<keyof ShowActivityTypesT, string> = {
  autonomousMowing: "Autonomous - Mowing",
  autonomousTransit: "Autonomous - Transit",
  manualMowing: "Manual - Mowing",
  manualTransit: "Manual - Transit",
};

export const getActivityTypeLabel = (type: keyof ShowActivityTypesT) =>
  activityTypesLabels[type];

export const getPointIcon = (outsideColor: string, insideColor: string) => {
  const encodedOutsideColor = outsideColor.replace("#", "%23");
  const encodedInsideColor = insideColor.replace("#", "%23");
  return `data:image/svg+xml;charset=UTF-8,<svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g transform="scale(22.727272) translate(-12 -8)">
<circle cx="23" cy="19" r="11" fill="${encodedInsideColor}"/>
<circle cx="23" cy="19" r="9" stroke="${encodedOutsideColor}" stroke-width="4"/>
    </g>
</svg>
`;
};

const constructSettingLabelDict = (types: RouteSettingType[]) => {
  const map: Record<number, string> = {};
  types.forEach((type) => (map[type.id] = type.displayName));
  return map;
};

const getLineMiddlePosition = (
  coordinates: LatLngT[]
): { latitude: number; longitude: number } => {
  if (coordinates.length > 2) {
    const middleCoord = coordinates[Math.round(coordinates.length / 2)];
    return { latitude: middleCoord.lat, longitude: middleCoord.lng };
  } else if (coordinates.length == 2) {
    return {
      latitude: (coordinates[0].lat + coordinates[1].lat) / 2,
      longitude: (coordinates[0].lng + coordinates[1].lng) / 2,
    };
  } else {
    return {
      latitude: coordinates[0]?.lat || 0,
      longitude: coordinates[0]?.lng || 0,
    };
  }
};

function roundNumber(val: string) {
  const num = parseFloat(val);
  if (isNaN(num) || Number.isInteger(num)) {
    return val;
  }

  return num.toFixed(2);
}

export const getLineInfo = (
  line: CoordinateT<"route">,
  types: RouteSettingType[],
  settings: RouteLineSetting[]
): RouteLineInfo => {
  const setting = settings.find((s) => s.areaId === line.id);
  const labelDict = constructSettingLabelDict(types);

  const position = getLineMiddlePosition(line.coordinates);
  const items =
    setting?.items.map((item) => ({
      label: labelDict[item.routeSettingId],
      value: roundNumber(item.value),
    })) || [];

  return {
    ...position,
    settings: items,
    lineId: line.id,
  };
};
