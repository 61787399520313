import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_SHOW_ACTIVITY_TYPES } from "../../constants";
import {
  CoordinatesT,
  MapLayers,
  RouteLayers,
  ShowActivityTypesT,
} from "../../types/Route";
import { setLayers } from "../helpers/jobRouteTypeHelpers";
import { JobProgress, NextCoordinatesByMower } from "../../types/Job";

interface JobRouteType {
  routeCoordinates: CoordinatesT<"route"> | null;
  activeRouteLayers: RouteLayers;
  showActivityByMower: Record<number, boolean> | null;
  shownNotifications: Record<string, boolean> | null;
  segmentsCoordinates: Record<number, CoordinatesT<"route">> | null;
  activeSegmentsLayers: Record<number, RouteLayers> | null;
  lastSegments: number[];
  lastCreatedSegmentIds: number[];
  showMowingArrows: boolean;
  routeIsLoading: boolean;
  segmentIsLoading: boolean;
  hidePageHeader: boolean;
  showFullOverview: boolean;
  showRadioModeWarning: boolean;
  showActivityTypes: ShowActivityTypesT;
  jobProgress: { mower: JobProgress[] } | null;
  nextCoordinates: Record<number, NextCoordinatesByMower>;
}

export const routeLayersInitial: RouteLayers = {
  route: [],
  startPoint: undefined,
  endPoint: undefined,
  endPointChecked: false,
  startPointChecked: false,
  routeChecked: false,
  checkedAll: false,
  activityChecked: false,
};

const initialState: JobRouteType = {
  routeCoordinates: null,
  activeRouteLayers: routeLayersInitial,
  showActivityByMower: null,
  shownNotifications: null,
  segmentsCoordinates: null,
  activeSegmentsLayers: null,
  lastSegments: [],
  lastCreatedSegmentIds: [],
  showMowingArrows: false,
  routeIsLoading: false,
  segmentIsLoading: false,
  hidePageHeader: false,
  showFullOverview: false,
  showRadioModeWarning: false,
  showActivityTypes: DEFAULT_SHOW_ACTIVITY_TYPES,
  jobProgress: null,
  nextCoordinates: {},
};

export const jobRouteTypeSlice = createSlice({
  name: "jobRouteType",
  initialState,
  reducers: {
    setNextCoordinates: (
      state,
      action: PayloadAction<Record<number, NextCoordinatesByMower>>
    ) => ({
      ...state,
      nextCoordinates: action.payload,
    }),
    setJobProgress: (
      state,
      action: PayloadAction<{ mower: JobProgress[] }>
    ) => {
      if (action.payload.mower) {
        state.jobProgress = action.payload;
      }
    },
    setRouteCoordinates: (
      state,
      action: PayloadAction<CoordinatesT<"route">>
    ) => {
      state.routeCoordinates = action.payload;
    },
    setActiveRouteLayers: (state, action: PayloadAction<RouteLayers>) => {
      state.activeRouteLayers = action.payload;
    },
    setShowActivityByMower: (
      state,
      action: PayloadAction<Record<number, boolean>>
    ) => {
      state.showActivityByMower = action.payload;
    },
    toggleShowActivityByMower: (state, action: PayloadAction<number>) => {
      const mowerId = action.payload;
      if (!state.showActivityByMower) return;
      state.showActivityByMower[mowerId] = !state.showActivityByMower[mowerId];
    },
    setShownNotification: (
      state,
      action: PayloadAction<Record<string, boolean> | null>
    ) => {
      state.shownNotifications = action.payload;
    },
    setAllShownNotification: (state, action: PayloadAction<boolean>) => {
      if (!state.shownNotifications) return;
      const newShown: Record<string, boolean> = {};
      Object.keys(state.shownNotifications).forEach(
        (key) => (newShown[key] = action.payload)
      );
      state.shownNotifications = newShown;
    },
    toggleShownNotification: (state, action: PayloadAction<string>) => {
      if (!state.shownNotifications) return;
      state.shownNotifications[action.payload] =
        !state.shownNotifications[action.payload];
    },
    setSegmentsCoordinates: (
      state,
      action: PayloadAction<Record<number, CoordinatesT<"route">>>
    ) => {
      state.segmentsCoordinates = action.payload;
    },
    setActiveSegmentLayers: (
      state,
      action: PayloadAction<Record<number, RouteLayers> | null>
    ) => {
      state.activeSegmentsLayers = action.payload;
    },
    setLastSegments: (state, action: PayloadAction<number[]>) => {
      state.lastSegments = action.payload;
    },
    setShowMowingArrows: (state, action: PayloadAction<boolean>) => {
      state.showMowingArrows = action.payload;
    },
    setLastCreatedSegmentIds: (state, action: PayloadAction<number[]>) => {
      state.lastCreatedSegmentIds = action.payload;
    },
    setRouteIsLoading: (state, action: PayloadAction<boolean>) => {
      state.routeIsLoading = action.payload;
    },
    setSegmentIsLoading: (state, action: PayloadAction<boolean>) => {
      state.segmentIsLoading = action.payload;
    },
    changeActiveRouteLayers: (state, action: PayloadAction<MapLayers>) => {
      const layer = action.payload;
      const routeLayers = state.activeRouteLayers;
      state.activeRouteLayers = setLayers(layer, routeLayers, false);
    },
    toggleActiveSegmentsLayers: (
      state,
      action: PayloadAction<{
        layer: MapLayers;
        segmentIds: number[];
      }>
    ) => {
      const segmentLayers = { ...state.activeSegmentsLayers };
      if (!segmentLayers) return;
      const { layer, segmentIds } = action.payload;
      const lastSegments = state.lastSegments;
      segmentIds.forEach((segmentId) => {
        if (lastSegments.length > 0 && !lastSegments.includes(segmentId)) {
          state.lastSegments = [];
        }
        segmentLayers[segmentId] = setLayers(
          layer,
          segmentLayers[segmentId],
          true
        );
      });
      state.activeSegmentsLayers = segmentLayers;
    },
    changeActiveSegmentsLayers: (
      state,
      action: PayloadAction<{ segmentIdsToShow: number[] }>
    ) => {
      if (!state.activeSegmentsLayers) return state;
      const { segmentIdsToShow } = action.payload;
      const segmentLayers = { ...state.activeSegmentsLayers };
      if (segmentIdsToShow.length === 0) {
        for (const key in segmentLayers) {
          segmentLayers[key] = {
            ...segmentLayers[key],
            checkedAll: true,
            endPointChecked: true,
            startPointChecked: true,
            routeChecked: true,
            activityChecked: true,
          };
        }
      } else {
        for (const key in segmentLayers) {
          const isChecked = segmentIdsToShow.includes(parseInt(key));
          segmentLayers[key] = {
            ...segmentLayers[key],
            checkedAll: isChecked,
            endPointChecked: isChecked,
            startPointChecked: isChecked,
            routeChecked: isChecked,
            activityChecked: isChecked,
          };
        }
      }
      state.activeSegmentsLayers = segmentLayers;
    },
    clearJobAction: () => {
      return initialState;
    },
    clearSegmentsData: (state) => {
      return {
        ...state,
        activeSegmentsLayers: null,
        segmentsCoordinates: null,
        lastCreatedSegmentIds: [],
        lastSegments: [],
      };
    },
    setHidePageHeader: (state, action: PayloadAction<boolean>) => {
      state.hidePageHeader = action.payload;
    },
    setShowFullOverview: (state, action: PayloadAction<boolean>) => {
      state.showFullOverview = action.payload;
    },
    setShowRadioModeWarning: (state, action: PayloadAction<boolean>) => {
      if (state.showRadioModeWarning === action.payload) return;
      state.showRadioModeWarning = action.payload;
    },
    setShowActivityType: (
      state,
      action: PayloadAction<{ type: keyof ShowActivityTypesT; show: boolean }>
    ) => {
      state.showActivityTypes = {
        ...state.showActivityTypes,
        [action.payload.type]: action.payload.show,
      };
    },
    setShowActivityTypes: (state, action: PayloadAction<boolean>) => {
      state.showActivityTypes.autonomousMowing = action.payload;
      state.showActivityTypes.autonomousTransit = action.payload;
      state.showActivityTypes.manualMowing = action.payload;
      state.showActivityTypes.manualTransit = action.payload;
    },
  },
});

export const fetchRouteCoordinatesAction = createAction<{ routeId: number }>(
  "FETCH_ROUTE_COORDINATES"
);
export type FetchRouteCoordinatesActionType = ReturnType<
  typeof fetchRouteCoordinatesAction
>;

export const fetchSegmentsCoordinatesAction = createAction<{
  jobId: string | number;
  segmentIds: number[];
  lastSegments: number[];
}>("FETCH_SEGMENTS_COORDINATES");
export type FetchSegmentsCoordinatesActionType = ReturnType<
  typeof fetchSegmentsCoordinatesAction
>;

export const subscribeToJobProgressAction = createAction<{
  jobId: number | string;
}>("SUBSCRIBE_TO_JOB_PROGRESS");
export type SubscribeToJobProgressActionType = ReturnType<
  typeof subscribeToJobProgressAction
>;

export const subscribeToNextCoordinatesUpdate = createAction<{
  jobId: number | string;
}>("SUBSCRIBE_TO_NEXT_COORDINATES");
export type SubscribeToNextCoordinatesUpdateActionType = ReturnType<
  typeof subscribeToNextCoordinatesUpdate
>;

export const {
  setRouteCoordinates,
  setActiveRouteLayers,
  setShowActivityByMower,
  toggleShowActivityByMower,
  setShownNotification,
  setAllShownNotification,
  toggleShownNotification,
  setSegmentsCoordinates,
  setActiveSegmentLayers,
  setLastSegments,
  setShowMowingArrows,
  setLastCreatedSegmentIds,
  setRouteIsLoading,
  setSegmentIsLoading,
  changeActiveRouteLayers,
  toggleActiveSegmentsLayers,
  changeActiveSegmentsLayers,
  clearJobAction,
  clearSegmentsData,
  setHidePageHeader,
  setShowFullOverview,
  setShowRadioModeWarning,
  setShowActivityType,
  setShowActivityTypes,
  setJobProgress,
  setNextCoordinates,
} = jobRouteTypeSlice.actions;
