const HEX_COLOR_REGEX = /^#?[0-9A-F]{6}[0-9a-f]{0,2}$/i;

function getHexValue(value: string) {
  return parseInt(value, 16);
}

function getHexString(value: number) {
  const hex = value.toString(16);
  if (hex.length < 2) {
    return "0" + hex;
  }
  return hex;
}

function parseColorHex(
  value: string
): [number, number, number, number | undefined] {
  const red = getHexValue(value.substring(0, 2));
  const green = getHexValue(value.substring(2, 4));
  const blue = getHexValue(value.substring(4, 6));
  const alpha =
    value.length > 6 ? getHexValue(value.substring(6, 8)) : undefined;
  return [red, green, blue, alpha];
}

function encodeColorHex(
  red: number,
  green: number,
  blue: number,
  alpha?: number
) {
  return `#${getHexString(red)}${getHexString(green)}${getHexString(blue)}${
    alpha ? getHexString(alpha) : ""
  }`;
}

function clamp(min: number, value: number, max: number) {
  return Math.min(max, Math.max(min, value));
}

export function changeColorLightness(color: string, multiplier: number) {
  if (!HEX_COLOR_REGEX.test(color)) {
    return color;
  }

  let trimmed = color;
  if (trimmed.startsWith("#")) {
    trimmed = trimmed.substring(1);
  }

  const constituents = parseColorHex(trimmed);
  for (let i = 0; i < 3; i++) {
    constituents[i] = clamp(
      0,
      Math.round((constituents[i] as number) * multiplier),
      0xff
    );
  }

  return encodeColorHex(...constituents);
}
