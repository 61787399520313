import React, { useEffect, useRef, useState } from "react";
import {
  Controller,
  ControllerProps,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { Input, InputProps } from "reactstrap";
import { getFieldError } from "../../../functions/forms";
import { SelectOptionsT } from "../../../types/Common";
import styles from "./searchableSelect.module.scss";
import classNames from "classnames";
import { Icon } from "../Icon";

export type SearchableSelectControllerT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  id?: string;
  input?: InputProps;
  options?: SelectOptionsT;
  placeholder?: string;
  errors?: FieldErrors<TFieldValues>;
  isLoading?: boolean;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SearchableSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  name,
  control,
  input,
  errors,
  placeholder,
  options,
  defaultValue,
  onClick,
}: SearchableSelectControllerT<TFieldValues, TName>) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const fieldError = getFieldError(name, errors);
  const selectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const eventHandler = (event: MouseEvent) => {
      if (
        isOpen &&
        selectRef.current &&
        event.target instanceof HTMLElement &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", eventHandler);
    return () => {
      document.removeEventListener("click", eventHandler);
    };
  }, [isOpen]);

  const lowerCaseQuery = query.toLowerCase();
  const filteredOptions =
    options &&
    options.filter((option) =>
      option.label.toLowerCase().includes(lowerCaseQuery)
    );

  const isInvalid = !!fieldError?.message;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div
          className={classNames(styles.container, isInvalid && "is-invalid")}
          onClick={() => setIsOpen((prev) => !prev)}
          ref={selectRef}
        >
          <Input
            {...input}
            id={id}
            invalid={isInvalid}
            className={classNames(styles.input, isInvalid && styles.invalid)}
            value={
              field.value
                ? options?.find(
                    (option) => String(option.value) === String(field.value)
                  )?.label
                : query
            }
            placeholder={placeholder}
            onFocus={() => {
              setQuery("");
              field.onChange("");
            }}
            onChange={(event) => {
              setQuery(event.target.value);
              if (onClick) {
                onClick(event);
              }
            }}
          />
          <div className={styles.chevron}>
            <Icon icon="angle-down" />
          </div>
          {isOpen && (
            <div className={styles.dropdown}>
              <div className={styles.optionList}>
                {filteredOptions &&
                  filteredOptions.map((option) => (
                    <div
                      className={styles.option}
                      key={option.value}
                      onClick={(e) => {
                        setQuery(option.label);
                        field.onChange(option.value);
                        setIsOpen(false);
                        e.stopPropagation();
                      }}
                      onBlur={field.onBlur}
                    >
                      {option.label}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};
