import { DropdownItemT } from "../../components/organisms/Map/MapDropdown";
import {
  JOB_ROUTE_TYPE_COLORS,
  PRAGUE_LAT_LNG,
  lineSymbol,
} from "../../constants";
import { changeColorLightness } from "../../functions/changeColorLightness";
import { getLayerName } from "../../functions/maps";
import { DropdownItemConfig } from "../../types/Common";
import { JobT, JobUserTaskT } from "../../types/Job";
import {
  CoordinatesT,
  LatLngT,
  RoutePreviewT,
  StylesT,
} from "../../types/Route";
import { DropdownItemPerimeter, PerimeterLayersType } from "./types";

export const getMapCenter = (job?: JobT): LatLngT => {
  if (job && job.latitude && job.longitude) {
    return {
      lat: job.latitude,
      lng: job.longitude,
    };
  }

  return PRAGUE_LAT_LNG;
};

export const getRouteId = (
  index: number,
  routePreviews?: RoutePreviewT[]
): number | undefined => {
  if (!routePreviews || routePreviews.length < 1) {
    return undefined;
  }
  return routePreviews[index].id;
};

// 1. segment polyline route type with segment color;
// 2. route polyline color undef;
// 3. regular job route polyline color undef;
// 4.regular job journey polylines;

export const getPolylineOptions = (
  styleType: keyof StylesT<"route" | "journey">,
  coordinatesStyles: StylesT<"route" | "journey">,
  showArrows: boolean,
  color?: { primaryColor: string; secondaryColor: string },
  darkened?: boolean
): google.maps.PolylineOptions => {
  const { LineStyle } = coordinatesStyles[styleType];

  // Set polyline color
  let strokeColor = `#${LineStyle.color}`;

  if (
    styleType === "mowingRoute" ||
    styleType === "transitRoute" ||
    styleType === "noGpsMowing"
  ) {
    if (color) {
      strokeColor = color.primaryColor;
    } else {
      strokeColor = JOB_ROUTE_TYPE_COLORS.plannedRoute;
    }
  }

  if (darkened) {
    strokeColor = changeColorLightness(strokeColor, 0.75);
  }

  // Set polyline width
  let strokeWeight = LineStyle.width;

  if (styleType === "transitRoute") {
    strokeWeight = 0;
  } else if (!color) {
    strokeWeight = LineStyle.width - 1;
  }

  // Set polyline icons
  const icons: google.maps.IconSequence[] = [];

  if (showArrows) {
    icons.push({
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 1.5,
      },
      repeat: "30px",
    });
  }

  if (styleType === "transitRoute") {
    icons.push({
      icon: lineSymbol,
      offset: "0",
      repeat: "15px",
    });
  }

  return {
    strokeColor,
    strokeOpacity: 1,
    strokeWeight,
    zIndex: color ? 800 : 200,
    icons,
  };
};

export const getPolygonOptions = (
  path: LatLngT[],
  styleType: keyof StylesT<"route">,
  coordinatesStyles: StylesT<"route">
): google.maps.PolygonOptions => {
  const currentStyle = coordinatesStyles[styleType];
  const fillColor = currentStyle.PolyStyle?.color;
  const { width } = currentStyle.LineStyle;

  return {
    strokeColor: JOB_ROUTE_TYPE_COLORS.calculatedRoute,
    strokeOpacity: 1,
    strokeWeight: width,
    paths: path,
    zIndex: 1,
    fillColor: "#" + fillColor,
    fillOpacity: 0.5,
  };
};

export const getRouteDropdownItems = (
  coordinateItems?: CoordinatesT<"route">,
  segmentColor?: {
    primaryColor: string;
    secondaryColor: string;
  }
) => {
  if (!coordinateItems) {
    return [];
  }
  const { coordinates, styles: coordStyles } = coordinateItems;
  const dropdownItems: DropdownItemT<number>[] = coordinates.map(
    ({ styleType }, index) => {
      let color = "#" + coordStyles[styleType].LineStyle.color;
      if (
        segmentColor &&
        (styleType === "mowingRoute" ||
          styleType === "startPoint" ||
          styleType === "endPoint")
      ) {
        color = segmentColor.primaryColor;
      }
      return {
        label: getLayerName(styleType),
        id: index,
        color,
      };
    }
  );
  return dropdownItems;
};

export const getPerimeterDropdownItems = (
  type: PerimeterLayersType,
  coordinateItems?: CoordinatesT<"route">
) => {
  if (!coordinateItems) {
    return [];
  }
  const { coordinates, styles: coordStyles } = coordinateItems;

  const dropdownItems = coordinates.reduce<DropdownItemPerimeter[]>(
    (acc, curr, index) => {
      if (type === "recording") {
        if (curr.styleType !== "geofence" && curr.styleType !== "noGoZone") {
          return acc;
        }
      } else {
        if (
          curr.styleType !== "endPoint" &&
          curr.styleType !== "startPoint" &&
          curr.styleType !== "mowingRoute" &&
          curr.styleType !== "transitRoute"
        ) {
          return acc;
        }
      }
      const color = "#" + coordStyles[curr.styleType].LineStyle.color;
      const duplicatedItemIndex = acc.findIndex(
        (item) => item.title === getLayerName(curr.styleType)
      );
      if (duplicatedItemIndex > 0) {
        acc[duplicatedItemIndex].ids.push(index);
      } else {
        acc.push({
          title: getLayerName(curr.styleType),
          ids: [index],
          color,
          uniqueId: curr.styleType,
        });
      }
      return acc;
    },
    []
  );

  return dropdownItems;
};

export const renameLayer = (type: PerimeterLayersType): string => {
  return type === "calculated" ? "Calculated route" : "Recording";
};

const isSubItemChecked = (ids: number[], checkedIds: number[]) => {
  let isChecked = false;
  for (const id of ids) {
    if (checkedIds.includes(id)) {
      isChecked = true;
      break;
    }
  }
  return isChecked;
};

export const createSubmenuByType = (
  type: PerimeterLayersType,
  coordinates: CoordinatesT<"route">,
  checkedItems: number[],
  onSelect: (id: number[], type: PerimeterLayersType) => void
) => {
  const items = getPerimeterDropdownItems(type, coordinates);
  return items.map((item): DropdownItemConfig => {
    return {
      id: item.uniqueId,
      title: item.title,
      onSelect: () => onSelect(item.ids, type),
      selected: isSubItemChecked(item.ids, checkedItems),
    };
  });
};

export const showMowerTrajectory = (tasks: JobUserTaskT | null) => {
  if (!tasks)
    return {
      isRecordingPerimeter: false,
      isRecordingNoGoZone: false,
      isMowing: false,
    };
  const isRecordingPerimeter = tasks?.tasks.some(
    (task) => task.isActive && task.type === "manualModeToSetPerimeter"
  );

  const isRecordingNoGoZone = tasks?.tasks.some(
    (task) =>
      task.isActive &&
      task.type === "setNoGoZones" &&
      task.subtask?.items.some((subTask) => subTask.status === "start")
  );
  const isMowing = tasks?.tasks.every((task) => task.status === "done");

  return {
    isRecordingPerimeter,
    isRecordingNoGoZone,
    isMowing,
  };
};

export const shouldCleanTrajectory = (tasks: JobUserTaskT | null) => {
  if (!tasks) return false;
  const { isMowing, isRecordingNoGoZone, isRecordingPerimeter } =
    showMowerTrajectory(tasks);
  return !isMowing && !isRecordingNoGoZone && !isRecordingPerimeter;
};
